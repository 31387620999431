
import { UserDetail, VehicleType } from '@/models/dto'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { pluralize } from '@/utils/string'
import typeClient from '@/services/type'
import DriverInfoDriverPayTab from '@/components/DriverInfoDriverPayTab.vue'
import sidebar from '@/store/modules/sidebar'

@Component({
  components: {
    DriverInfoDriverPayTab,
  },
})
export default class EditDriverRatesSidebar extends Vue {
  @Prop({ required: true }) drivers: UserDetail[]

  pluralize = pluralize
  vehicleTypes: VehicleType[] = []
  loading = true

  get userIds(): number[] {
    return this.drivers.map((d) => d.userId)
  }

  get count(): number {
    return this.drivers?.length || 0
  }

  cancel(): void {
    sidebar.pop()
  }

  submit(): void {
    return
  }

  async mounted(): Promise<void> {
    const res = await typeClient.vehicleTypeTableView({ pageSize: -1 })
    this.vehicleTypes = res.data.resultList
    this.loading = false
  }
}
