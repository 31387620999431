
import { Vue, Component } from 'vue-property-decorator'
import DriverList from '@/components/DriverList.vue'
import Main from '@/layouts/Main.vue'

@Component({
  components: { DriverList, Main },
  metaInfo: {
    title: 'Drivers',
  },
})
export default class Drivers extends Vue {}
