
import { Vue, Component, Watch } from 'vue-property-decorator'
import { rules, validateRules } from '@/utils/rules'
import auth from '@/store/modules/auth'
import client from '@/services/driver'
import { EventBus } from '@/utils/eventBus'
import { DriverRequest } from '@/models/dto/Driver'
import sidebar from '@/store/modules/sidebar'
import { formattedPhoneToNumber } from '@/utils/string'
import EditUserSidebar from './EditUserSidebar.vue'
import { parseExistingUserError } from '@/utils/error'

@Component({})
export default class DriverForm extends Vue {
  auth = auth
  rules = rules

  submitting = false
  loading = false

  driver = new DriverRequest()
  errors = {
    email: null,
  }

  @Watch('email')
  emailChanged(): void {
    this.errors.email = null
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }

    this.submitting = true
    const driverRequest: DriverRequest = {
      ...this.driver,
      phone: formattedPhoneToNumber(this.driver.phone),
      active: true,
      companyId: this.auth.user.companyId,
      companyName: this.auth.user.companyName,
      driverSupportedVehicles: [],
      groupId: 4,
      licenseExpirationDay: 0, 
      licenseExpirationMonth: 0,
      licenseExpirationYear: 0,
      licenseNumber: null,
      locale: 'en_US',
      treatAsDriver: true,
      userRoleNames: ['is_driver'],
    }

    try {
      const res = await client.create(driverRequest)
      const driver = res.data.driver

      EventBus.$emit('drivers:refresh')
      sidebar.push({
        component: EditUserSidebar,
        props: {
          user: driver,
          driverOnly: true,
        },
        width: 675,
        wide: true,
      })
    } catch (err) {
      this.handleDriverClientError(err)
    } finally {
      this.submitting = false
    }
  }

  handleDriverClientError(e: any): void {
    const { message } = e.response.data
    if (message?.includes('email')) {
      this.errors.email = parseExistingUserError(message)
      return
    }
    EventBus.$emit('drivers:error')
  }

  resetErrors(): void {
    this.errors = {
      email: null,
    }
  }

  clear(): void {
    this.driver = new DriverRequest()
    this.resetErrors()
    this.submitting = false
    sidebar.pop()
  }
}
