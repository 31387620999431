
import { Component, Vue } from 'vue-property-decorator'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import client from '@/services/driver'
import userClient from '@/services/user'
import { UserDetail } from '@/models/dto'
import Placeholder from '@/views/Placeholder.vue'
import DriverForm from './DriverForm.vue'
import { NAME_COLUMN_WIDTH } from '@/data/tableViews'
import { phoneFormatFilter } from '@/utils/string'
import EditUserSidebar from './EditUserSidebar.vue'
import EditDriverRatesSidebar from './EditDriverRatesSidebar.vue'

@Component({
  components: {
    Placeholder,
    CUCollectionTable,
  },
})
export default class DriverList extends Vue {
  tableView = client.tableView

  refresh(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
  }

  error(e: any): void {
    // TODO
  }

  handleDriverToggle(): void {
    // TODO: Update 'active' field once able to be handled by the backend API.
    EventBus.$emit('refresh-tableview')
  }

  openDriverDetail(row: UserDetail): void {
    sidebar.popAllAndPush({
      component: EditUserSidebar,
      props: {
        user: row,
        driverOnly: true,
      },
      width: 675,
    })
  }

  handleAddDriver(): void {
    sidebar.push({
      component: DriverForm,
      props: {
        activeDriverId: null,
      },
      title: 'Add Driver',
      width: 675,
      wide: true,
    })
  }

  async handleDeleteDriver(userId: number): Promise<void> {
    await userClient.delete(userId)
    this.refresh()
  }

  handleEditRates(rows: UserDetail[]): void {
    sidebar.push({
      component: EditDriverRatesSidebar,
      title: 'Edit Driver Rates',
      props: {
        drivers: rows,
      },
    })
  }

  // Table //
  count = 0
  search = ''
  dropdown = ['Placeholder_01', 'Placeholder_02']

  actions: TableAction[] = [
    // Commenting out for v1
    // {
    //   displayText: 'Edit driver rates',
    //   key: 'edit-driver-rates',
    //   icon: 'edit',
    //   iconWidth: '20px',
    //   iconHeight: '20px',
    //   action: (rows: UserDetail[]): void => {
    //     EventBus.$emit('drivers:edit-rates', rows)
    //   },
    // },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: 'ae2e286b-0778-4f5a-9bfb-c6811dd895e8',
      text: 'Name',
      value: 'driver-name',
      computedText: (row: UserDetail): string =>
        `${row.firstName} ${row.lastName}`,
      type: 'link',
      filterable: true,
      filterProp: 'fullName',
      filterBySearch: true,
      filterAsIs: true,
      clickEvent: 'drivers:open-driver-detail',
      sortProp: 'lastName',
      width: NAME_COLUMN_WIDTH,
      unsetWidthOnBreakpoint: 'mdAndUp',
    },
    {
      _t_id: '0278de16-2a53-458b-9c37-3a579e6a4fdd',
      text: 'Phone Number',
      value: 'phone',
      computedText: (row: UserDetail): string => phoneFormatFilter(row.phone),
      filterable: true,
      filterProp: 'phone',
      filterBySearch: true,
      width: 160,
      unsetWidthOnBreakpoint: 'mdAndUp',
    },
    {
      _t_id: '51cee37b-7d7a-4fcd-b27a-5354b4fc6f01',
      text: 'Email',
      value: 'email',
      filterable: true,
      filterProp: 'email',
      filterBySearch: true,
      width: 225,
    },
    {
      _t_id: 'b4ed14bb-a991-4a1e-9863-2b979376119a',
      text: 'Active',
      computedText: (row: UserDetail): string =>
        row.driverActivated ? 'Active' : 'Not Active',
      value: 'driverActivated',
      filterProp: 'driverActivated',
      filterType: 'contains',
      sortable: true,
      width: 100,
    },
  ]

  mounted(): void {
    EventBus.$on('create-user:refresh', this.refresh)
    EventBus.$on('create-user:error', this.error)
    EventBus.$on('edit-user:refresh', this.refresh)
    EventBus.$on('edit-user:error', this.error)
    EventBus.$on('drivers:refresh', this.refresh)
    EventBus.$on('drivers:error', this.error)
    EventBus.$on('drivers:open-driver-detail', this.openDriverDetail)
    EventBus.$on('drivers:handle-driver-toggle', this.handleDriverToggle)
    EventBus.$on('drivers:delete-driver', this.handleDeleteDriver)
    EventBus.$on('drivers:edit-rates', this.handleEditRates)
  }

  beforeDestroy(): void {
    EventBus.$off('create-user:refresh', this.refresh)
    EventBus.$off('create-user:error', this.error)
    EventBus.$off('edit-user:refresh', this.refresh)
    EventBus.$off('edit-user:error', this.error)
    EventBus.$off('drivers:refresh', this.refresh)
    EventBus.$off('drivers:error', this.error)
    EventBus.$off('drivers:open-driver-detail', this.openDriverDetail)
    EventBus.$off('drivers:handle-driver-toggle', this.handleDriverToggle)
    EventBus.$off('drivers:delete-driver', this.handleDeleteDriver)
    EventBus.$off('drivers:edit-rates', this.handleEditRates)
  }
}
